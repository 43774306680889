.footer {
    display: block;
    /*background-color: #dc2f02;*/
    padding: 3rem 1rem;
}

.footer div {
    display: flex;
}

.footer .legend {
    /*background-color: #ffba08;*/
}

.footer .legend .legendItem p {
    margin: 0 1rem;
}
