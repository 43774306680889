.create {
    /*background-color: coral;*/
}

.create h1 {
    /*background-color: chartreuse;*/
    padding: 1rem;
}

.create nav {
    display: flex;
    justify-content: center;
}

.create nav button {
    margin: 1rem;
}

.create h2 {
    /*background-color: aqua;*/
    padding: 1rem 0;
}

.create form {
    /*background-color: cornflowerblue;*/
    max-width: 600px;
    padding: 0 1rem;
    margin: 0 auto;
}

.create form p {
    /*background-color: cornflowerblue;*/
    display: block;
    padding: 1rem 0;
    font-weight: bold;
}

.editorWrapper {
    background-color: rgba(221, 221, 221, 0.2);
    height: fit-content;
    width: 100%;
}

.create table {
    /*background-color: cornflowerblue;*/
    min-width: 100%;
    margin: 2rem 0;
    padding: 0 0 4rem 0;
    font-family: "Open Sans", sans-serif;
}

.create table tr th {
    text-align: left;
}

.create table tr th,
.create table tr td {
    padding: 0.5rem 0;
}

.create table tr td p {
    padding: 0;
    margin: 0;
    font-weight: unset;
    display: block;
}

.create table tr:nth-child(even) {
    background-color: rgba(221, 221, 221, 0.2);
}

.create table tr:nth-child(odd) {
    background-color: rgba(221, 221, 221, 0.6);
}

.updateComments {
    /*background-color: cornflowerblue;*/
    width: 100%;
}

.updateComments * {
    font-family: "Open Sans", sans-serif;
}

.updateComments ul,
.updateComments ol {
    /*background-color: coral;*/
    list-style-position: inside;
}
