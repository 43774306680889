.dashboard {
    /*background-color: coral;*/
}

.dashboard h1 {
    /*background-color: #dddddd;*/
    padding: 1rem;
}

.dashboard nav {
    /*background-color: chartreuse;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard nav button {
    margin: 1rem;
}

.dashboard h2 {
    /*background-color: aqua;*/
    padding: 1rem;
}

.dashboardFilter {
    /*background-color: dodgerblue;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}

.dashboardFilter h2 {
    width: 100%;
}

.dashboardFilter div {
    /*background-color: rgba(221, 221, 221, 0.2);*/
    width: 50%;
    padding-left: 1rem;
}
.dashboardFilter p {
    /*background-color: aqua;*/
    padding: 0.5rem 0;
    font-weight: bold;
}

.dashboardFilter button {
    display: block;
    margin: 2rem auto;
}

.dashboardFilterInput {
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}

.dashboardFilterInput input {
    margin-right: 1rem;
}

.dashboardTable {
    /*background-color: chartreuse;*/
    max-width: 100vw;
}

.dashboardTable table {
    width: 100%;
    max-width: 100vw;
    margin: 0 auto 4rem;
    font-family: "Open Sans", sans-serif;
}

.dashboardTable table tr th {
    text-align: left;
}

.dashboardTable table tr th,
.dashboardTable table tr td {
    padding: 0.5rem 0;
}

.dashboardTable table tr td h3 {
    font-family: "Open Sans", sans-serif;
    font-size: unset;
}

.dashboardTable table tr:nth-child(even) {
    background-color: rgba(221, 221, 221, 0.2);
}

.dashboardTable table tr:nth-child(odd) {
    background-color: rgba(221, 221, 221, 0.6);
}
