.home {
    /*background-color: coral;*/
}

.home h1 {
    padding: 1rem;
}

.home section {
    /*background-color: cornsilk;*/
    padding: 1rem;
    border-bottom: 1px solid rgba(221, 221, 221, 0.7);
}

.home section h2,
.home section h3 {
    margin-bottom: 1rem;
}

.home section table {
    height: 300px;
    width: 100%;
}

.home section table th {
    text-align: left;
    text-transform: uppercase;
    font-weight: lighter;
}

.home section table tr td,
.home section table tr th {
    /*background-color: #0077b6;*/
    padding-left: 0.5rem;
}

.home section table tr:nth-child(even) {
    background-color: rgba(221, 221, 221, 0.6);
}
