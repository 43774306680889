.signIn {
    /*background-color: green;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.signIn form {
    /*background-color: chartreuse;*/
    height: fit-content;
    width: 90%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
}

.signIn form button {
    display: block;
    width: fit-content;
    margin: 0 auto;
}
