@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

h1,
h2,
h3 {
    font-family: "Poppins", sans-serif;
}

p,
span,
label {
    font-family: "Open Sans", sans-serif;
}

main {
    /*background-color: chartreuse;*/
    height: 100vh;
    max-width: 1024px;
    margin: 0 auto;
}

span[icon="error"] {
    color: #dc2f02 !important;
}

span[icon="tick-circle"] {
    color: green !important;
}

span[icon="warning-sign"] {
    color: #ffba08 !important;
}

span[icon="info-sign"] {
    color: #0077b6 !important;
}

.Home_home__3gg6u {
    /*background-color: coral;*/
}

.Home_home__3gg6u h1 {
    padding: 1rem;
}

.Home_home__3gg6u section {
    /*background-color: cornsilk;*/
    padding: 1rem;
    border-bottom: 1px solid rgba(221, 221, 221, 0.7);
}

.Home_home__3gg6u section h2,
.Home_home__3gg6u section h3 {
    margin-bottom: 1rem;
}

.Home_home__3gg6u section table {
    height: 300px;
    width: 100%;
}

.Home_home__3gg6u section table th {
    text-align: left;
    text-transform: uppercase;
    font-weight: lighter;
}

.Home_home__3gg6u section table tr td,
.Home_home__3gg6u section table tr th {
    /*background-color: #0077b6;*/
    padding-left: 0.5rem;
}

.Home_home__3gg6u section table tr:nth-child(even) {
    background-color: rgba(221, 221, 221, 0.6);
}

.Footer_footer__3gTnX {
    display: block;
    /*background-color: #dc2f02;*/
    padding: 3rem 1rem;
}

.Footer_footer__3gTnX div {
    display: -webkit-flex;
    display: flex;
}

.Footer_footer__3gTnX .Footer_legend__3ZE9f {
    /*background-color: #ffba08;*/
}

.Footer_footer__3gTnX .Footer_legend__3ZE9f .Footer_legendItem__2Q7_z p {
    margin: 0 1rem;
}

.SignIn_signIn__3PUli {
    /*background-color: green;*/
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100vw;
}

.SignIn_signIn__3PUli form {
    /*background-color: chartreuse;*/
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    max-width: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.SignIn_signIn__3PUli form button {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.Dashboard_dashboard__IqoRr {
    /*background-color: coral;*/
}

.Dashboard_dashboard__IqoRr h1 {
    /*background-color: #dddddd;*/
    padding: 1rem;
}

.Dashboard_dashboard__IqoRr nav {
    /*background-color: chartreuse;*/
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.Dashboard_dashboard__IqoRr nav button {
    margin: 1rem;
}

.Dashboard_dashboard__IqoRr h2 {
    /*background-color: aqua;*/
    padding: 1rem;
}

.Dashboard_dashboardFilter__1ETex {
    /*background-color: dodgerblue;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: stretch;
            align-items: stretch;
}

.Dashboard_dashboardFilter__1ETex h2 {
    width: 100%;
}

.Dashboard_dashboardFilter__1ETex div {
    /*background-color: rgba(221, 221, 221, 0.2);*/
    width: 50%;
    padding-left: 1rem;
}
.Dashboard_dashboardFilter__1ETex p {
    /*background-color: aqua;*/
    padding: 0.5rem 0;
    font-weight: bold;
}

.Dashboard_dashboardFilter__1ETex button {
    display: block;
    margin: 2rem auto;
}

.Dashboard_dashboardFilterInput__2RURJ {
    background-color: transparent;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem;
}

.Dashboard_dashboardFilterInput__2RURJ input {
    margin-right: 1rem;
}

.Dashboard_dashboardTable__LthWT {
    /*background-color: chartreuse;*/
    max-width: 100vw;
}

.Dashboard_dashboardTable__LthWT table {
    width: 100%;
    max-width: 100vw;
    margin: 0 auto 4rem;
    font-family: "Open Sans", sans-serif;
}

.Dashboard_dashboardTable__LthWT table tr th {
    text-align: left;
}

.Dashboard_dashboardTable__LthWT table tr th,
.Dashboard_dashboardTable__LthWT table tr td {
    padding: 0.5rem 0;
}

.Dashboard_dashboardTable__LthWT table tr td h3 {
    font-family: "Open Sans", sans-serif;
    font-size: unset;
}

.Dashboard_dashboardTable__LthWT table tr:nth-child(even) {
    background-color: rgba(221, 221, 221, 0.2);
}

.Dashboard_dashboardTable__LthWT table tr:nth-child(odd) {
    background-color: rgba(221, 221, 221, 0.6);
}

.Create_create__1vakm {
    /*background-color: coral;*/
}

.Create_create__1vakm h1 {
    /*background-color: chartreuse;*/
    padding: 1rem;
}

.Create_create__1vakm nav {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.Create_create__1vakm nav button {
    margin: 1rem;
}

.Create_create__1vakm h2 {
    /*background-color: aqua;*/
    padding: 1rem 0;
}

.Create_create__1vakm form {
    /*background-color: cornflowerblue;*/
    max-width: 600px;
    padding: 0 1rem;
    margin: 0 auto;
}

.Create_create__1vakm form p {
    /*background-color: cornflowerblue;*/
    display: block;
    padding: 1rem 0;
    font-weight: bold;
}

.Create_editorWrapper__1S1pw {
    background-color: rgba(221, 221, 221, 0.2);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}

.Create_create__1vakm table {
    /*background-color: cornflowerblue;*/
    min-width: 100%;
    margin: 2rem 0;
    padding: 0 0 4rem 0;
    font-family: "Open Sans", sans-serif;
}

.Create_create__1vakm table tr th {
    text-align: left;
}

.Create_create__1vakm table tr th,
.Create_create__1vakm table tr td {
    padding: 0.5rem 0;
}

.Create_create__1vakm table tr td p {
    padding: 0;
    margin: 0;
    font-weight: unset;
    display: block;
}

.Create_create__1vakm table tr:nth-child(even) {
    background-color: rgba(221, 221, 221, 0.2);
}

.Create_create__1vakm table tr:nth-child(odd) {
    background-color: rgba(221, 221, 221, 0.6);
}

.Create_updateComments__3GNfZ {
    /*background-color: cornflowerblue;*/
    width: 100%;
}

.Create_updateComments__3GNfZ * {
    font-family: "Open Sans", sans-serif;
}

.Create_updateComments__3GNfZ ul,
.Create_updateComments__3GNfZ ol {
    /*background-color: coral;*/
    list-style-position: inside;
}

.History_history__2-ZPc {
    /*background-color: coral;*/
}

.History_history__2-ZPc h1 {
    padding: 1rem;
}

.History_history__2-ZPc section {
    /*background-color: cornsilk;*/
    padding: 1rem;
    border-bottom: 1px solid rgba(221, 221, 221, 0.7);
}

.History_history__2-ZPc section h2,
.History_history__2-ZPc section h3 {
    margin-bottom: 1rem;
}

.History_history__2-ZPc section table {
    width: 100%;
    margin-bottom: 2rem;
}

.History_history__2-ZPc section table th {
    text-align: left;
    text-transform: uppercase;
    font-weight: lighter;
}

.History_history__2-ZPc section table tr {
    height: 40px;
}

.History_history__2-ZPc section table tr td,
.History_history__2-ZPc section table tr th {
    /*background-color: #0077b6;*/
    padding-left: 0.5rem;
}

.History_history__2-ZPc section table tr {
    background-color: rgba(221, 221, 221, 0.6);
}

.History_history__2-ZPc section table tr:first-child {
    background-color: transparent;
}

.Breadcrumbs_breadcrumbs__3nsPN {
    /*background-color: #ffba08;*/
    display: -webkit-flex;
    display: flex;
    padding-left: 1rem;
}

.Breadcrumbs_breadcrumbs__3nsPN div {
    display: -webkit-flex;
    display: flex;
}

.Detail_history__1jbTx {
    /*background-color: coral;*/
}

.Detail_history__1jbTx h1 {
    padding: 1rem;
}

.Detail_history__1jbTx section {
    /*background-color: cornsilk;*/
    padding: 1rem;
    border-bottom: 1px solid rgba(221, 221, 221, 0.7);
}

.Detail_history__1jbTx section h2,
.Detail_history__1jbTx section h3 {
    margin-bottom: 1rem;
}

.Detail_history__1jbTx section h2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Detail_history__1jbTx section h2 span {
    background-color: rgba(221, 221, 221, 0.7);
    margin: 0.5rem 0.5rem 0.5rem 0;
    padding: 0.2rem;
    border-radius: 0.5rem;
}

.Detail_history__1jbTx section h2 span:first-child {
    margin-left: 0.5rem;
}

.Detail_history__1jbTx section table {
    width: 100%;
    margin-bottom: 2rem;
}

.Detail_history__1jbTx section table th {
    text-align: left;
    text-transform: uppercase;
    font-weight: lighter;
}

.Detail_history__1jbTx section table tr {
    height: 40px;
}

.Detail_history__1jbTx section table tr td,
.Detail_history__1jbTx section table tr th {
    /*background-color: #0077b6;*/
    padding-left: 0.5rem;
}

.Detail_history__1jbTx section table tr {
    background-color: rgba(221, 221, 221, 0.6);
}

.Detail_history__1jbTx section table tr:first-child {
    background-color: transparent;
}

.Detail_updateComments__3Q_sJ ul,
.Detail_updateComments__3Q_sJ ol {
    /*background-color: coral;*/
    list-style-position: inside;
}

