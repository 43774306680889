.history {
    /*background-color: coral;*/
}

.history h1 {
    padding: 1rem;
}

.history section {
    /*background-color: cornsilk;*/
    padding: 1rem;
    border-bottom: 1px solid rgba(221, 221, 221, 0.7);
}

.history section h2,
.history section h3 {
    margin-bottom: 1rem;
}

.history section table {
    width: 100%;
    margin-bottom: 2rem;
}

.history section table th {
    text-align: left;
    text-transform: uppercase;
    font-weight: lighter;
}

.history section table tr {
    height: 40px;
}

.history section table tr td,
.history section table tr th {
    /*background-color: #0077b6;*/
    padding-left: 0.5rem;
}

.history section table tr {
    background-color: rgba(221, 221, 221, 0.6);
}

.history section table tr:first-child {
    background-color: transparent;
}
