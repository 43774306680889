@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

h1,
h2,
h3 {
    font-family: "Poppins", sans-serif;
}

p,
span,
label {
    font-family: "Open Sans", sans-serif;
}

main {
    /*background-color: chartreuse;*/
    height: 100vh;
    max-width: 1024px;
    margin: 0 auto;
}

span[icon="error"] {
    color: #dc2f02 !important;
}

span[icon="tick-circle"] {
    color: green !important;
}

span[icon="warning-sign"] {
    color: #ffba08 !important;
}

span[icon="info-sign"] {
    color: #0077b6 !important;
}
